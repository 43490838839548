import React from 'react'

import styles from './styles.module.scss'
import { ReactComponent as DeleteIcon } from '../../../../../images/close.svg'

const DeleteButton = ({onDelete}) => {
    const handleDeleteClick = () => {
        if (onDelete) {
            onDelete()
        }
    }

    return (
        <button className={styles.DeleteButton} onClick={handleDeleteClick}>
            <DeleteIcon className={styles.Icon} />
        </button>
    )
}

export default DeleteButton
