import React from 'react'

import GoogleSignInButton from '../../../_shared/Buttons/GoogleSignIn/GoogleSignInButton'

const UserSettings = () => {
    return (
        <div>
            user settings
            <div>
                <GoogleSignInButton />
            </div>
        </div>
    )
}

export default UserSettings
