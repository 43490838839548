import React, { useState, useEffect } from 'react'
import Textarea from 'react-textarea-autosize'

import styles from './styles.module.scss'

const TextInput = ({
    initialValue = '',
    focusOnShow = false,
    onEnter = null,
    onBlur = null,
    className = '',
    placeholder = 'Add a new task',
    minRows = 1
}) => {
    const [inputValue, setInputValue] = useState(initialValue)
    const [isAlreadyFocused, setAlreadyFocused] = useState(false)
    const [enterPressed, setEnterPressed] = useState(false)
    let textareaRef = null

    useEffect(() => {
        if (focusOnShow && !isAlreadyFocused) {
            textareaRef.focus()
            textareaRef.setSelectionRange(inputValue.length, inputValue.length)
            setAlreadyFocused(true)
        }
    }, [focusOnShow, inputValue.length, isAlreadyFocused, textareaRef])

    useEffect(() => {
        if (enterPressed) {
            if (onEnter) {
                onEnter(inputValue)
            }

            // Clear the input
            setInputValue('')

            // Clear the needs submit flag
            setEnterPressed(false)
        }
    }, [inputValue, enterPressed, onEnter])

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            setEnterPressed(true)
        }
    }

    const handleBlur = () => {
        if (onBlur) {
            onBlur(inputValue)
        }
    }

    return (
            <Textarea
                ref={(tag) => (textareaRef = tag)}
                className={[styles.TextInput, className].join(' ')}
                value={inputValue}
                placeholder={placeholder}
                onChange={(e) => (setInputValue(e.target.value))}
                onKeyPress={handleKeyPress}
                onBlur={handleBlur}
                minRows={minRows}
            />
    )
}

export default TextInput
