import {createSubstate} from 'react-substate'
import constants from './constants'

/*
task shape: {
    id: 'task-1',
    list: 'list-1',
    text: 'This is a task',
    category: 'orange',
    timeCreated: 1234567890
}
*/

const substates = {
    ui: createSubstate({
        app: null,
        isInitialized: false,
        user: null,
    }),

    tasks: createSubstate({
        db: {},
        active: [],
        completed: [],
        deleted: []
    }),

    lists: createSubstate({
        db: {
            'list-1': {
                id: 'list-1',
                name: 'Maybe someday',
                tasks: []
            },
            'list-2': {
                id: 'list-2',
                name: 'Do it soon',
                tasks: []
            },
            'list-3': {
                id: 'list-3',
                name: 'Do it now!',
                tasks: []
            },
        },
        order: [
            'list-1',
            'list-2',
            'list-3'
        ]

    }),

    categories: createSubstate({
        [constants.DEFAULT_CATEGORY]: {
            id: 'default',
            name: 'Default',
            color: '#EEEEEE',
            tasks: []
        },
        low: {
            id: 'low',
            name: 'Low',
            color: '#1F95EA',
            tasks: []
        },
        medium: {
            id: 'medium',
            name: 'Medium',
            color: '#9DDE3A',
            tasks: []
        },
        high: {
            id: 'high',
            name: 'High',
            color: '#F9CD5C',
            tasks: []
        },
        urgent: {
            id: 'urgent',
            name: 'Urgent',
            color: '#FA707C',
            tasks: []
        }
    })
}

export default substates
