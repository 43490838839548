import React, { useState } from 'react'
import { useSubstate } from 'react-substate'

import styles from './styles.module.scss'
import substates from '../../../../../modules/substates'
import Selector from './Selector'
import CategoryButton from './CategoryButton'

const CategoryIcon = ({categoryId, onCategorySelected}) => {
    const [categories] = useSubstate(substates.categories)
    const category = categories[categoryId]

    const [isSelectorVisible, setSelectorVisible] = useState(false)

    const handleButtonClick = () => {
        toggleSelector()
    }

    const toggleSelector = () => {
        setSelectorVisible(!isSelectorVisible)
    }

    const handleCategorySelected = (categoryId) => {
        if (onCategorySelected) {
            onCategorySelected(categoryId)
        }
    }

    return (
        <div className={styles.Category}>
            <CategoryButton
                categoryName={category.name}
                categoryColor={category.color}
                onClick={handleButtonClick}
            />
            <Selector
                isSelectorVisible={isSelectorVisible}
                onCategorySelected={handleCategorySelected}
                onFinished={toggleSelector}
            />
        </div>
    )
}

export default CategoryIcon
