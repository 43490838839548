import React, { useEffect } from 'react'
import { useSubstate } from 'react-substate'

import { ReactComponent as GoogleIcon } from '../../../../images/google-icon.svg'
import { doGoogleLogin, getData } from '../../../../modules/FirebaseUtils'
import { hydrate } from '../../../../modules/actions'
import substates from '../../../../modules/substates'

const GoogleSignInButton = () => {
    const [ui, dispatch] = useSubstate(substates.ui)
    const {app, user} = ui

    const onAuthSuccess = () => {
        console.log('sign in succeeded!')
    }

    const onAuthFailure = (error) => {
        console.error('sign in failed', error)
    }

    const handleSignInClick = () => {
        return doGoogleLogin(app, onAuthSuccess, onAuthFailure)
    }

    /**
     * Called when a user is logged in so that their data can be retrieved from
     * the server.
     */
    useEffect(() => {
        if (!user) {
            return
        }

        // Replace the local storage with what was received from the server
        // TODO: only do this if it is newer??
        // TODO: what if the data doesn't exist in the db yet
        // TODO: find a better home for this logic
        getData(app, user).then((data) => {
            hydrate(dispatch, data)
        }).catch((error) => {
            console.error(error)
        })
    }, [dispatch, app, user])

    return (
        <>
            <button onClick={handleSignInClick}>
                <GoogleIcon />
                Sign in with Google
            </button>
        </>
    )
}

export default GoogleSignInButton
