import React, { useEffect, useCallback } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { useSubstate } from 'react-substate'

import Home from './home/Home'
import { retrieve, persist } from '../../modules/StorageUtils'
import substates from '../../modules/substates'
import actions, { hydrate } from '../../modules/actions'
import CompletedTasks from './completed-tasks/CompletedTasks'
import DeletedTasks from './deleted-tasks/DeletedTasks'
import { createApp, setData } from '../../modules/FirebaseUtils'
import Settings from './settngs/Settings'

const Routes = () => {
    return (
        <>
            <Route component={Home} />
            <Route path='/completed-tasks' component={CompletedTasks} />
            <Route path='/deleted-tasks' component={DeletedTasks} />
            <Route path='/settings' component={Settings} />
        </>
    )
}

const App = () => {
    const [ui, dispatchUi] = useSubstate(substates.ui)
    const [tasks, dispatchTasks] = useSubstate(substates.tasks)
    const [lists, dispatchLists] = useSubstate(substates.lists)
    const [categories, dispatchCategories] = useSubstate(substates.categories)

    /*
     * The first time we render, retrieve any saved data and load it into the substate
     */
    useEffect(() => {
        const persistedData = retrieve()
        if (persistedData) {
            hydrate(dispatchTasks, dispatchLists, dispatchCategories, persistedData)
        }
        dispatchUi(actions.ui.setInitialized, true)
    }, [dispatchCategories, dispatchLists, dispatchTasks, dispatchUi])

    /*
     * Each time one of the substates stored in local storage is updated, persist the data
     */
    useEffect(() => {
        // Avoid persisting if the data hasn't yet been loaded
        if (ui.isInitialized) {
            const data = { tasks, lists, categories }

            // Persist in local storage
            persist(data)

            // Send to firebase, if enabled and available
            if (ui.app && ui.user) {
                setData(ui.app, ui.user, data)
            }
        }
    }, [categories, lists, tasks, ui.app, ui.isInitialized, ui.user])

    /**
     * Calllback passed to firebase's onAuthStateChange.
     */
    const onAuthStateChanged = useCallback((user) => {
        // If user is not null, then we assume we are signed in
        dispatchUi(actions.ui.setUser, user)
    }, [dispatchUi])

    /*
     * Initializes the firebase app and sets up the "app" state
     */
    useEffect(() => {
        createApp(onAuthStateChanged).then((app) => {
            dispatchUi(actions.ui.setApp, app)
        })
    }, [dispatchUi, onAuthStateChanged])

    return (
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
    )
}

export default App
