import styles from './styles.module.scss'

import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import DeleteButton from './Buttons/DeleteButton'
import CompleteButton from './Buttons/CompleteButton'
import { useDispatch } from 'react-substate'
import Category from './Category/Category'
import Content from './Content/Content'
import {
    completeTask,
    deleteTask,
    setCategory,
    updateText
} from '../../../../modules/TaskOperations'
import substates from '../../../../modules/substates'

const Task = ({task, index}) => {
    const dispatchTasks = useDispatch(substates.tasks)
    const dispatchLists = useDispatch(substates.lists)
    const dispatchCategories = useDispatch(substates.categories)

    const getClasses = (isDragging) => {
        const classes = [styles.Task]
        if (isDragging) {
            classes.push(styles.Dragging)
        }

        return classes.join(' ')
    }

    return (
        <Draggable draggableId={task.id} index={index}>
            {(provided, snapshot) => (
                <div
                    className={styles.TaskContainer}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className={getClasses(snapshot.isDragging)}>
                        <div className={styles.Left}>
                            <CompleteButton
                                taskId={task.id}
                                parentListId={task.list}
                                index={index}
                                onComplete={() => (completeTask(dispatchTasks, dispatchLists, task.list, task.id))}
                            />
                        </div>

                        <div className={styles.MiddleContainer}>
                            <Content
                                text={task.text}
                                onEditFinish={(value) => (updateText(dispatchTasks, value, task.id))}
                            />
                        </div>

                        <div className={styles.CategoryIconContainer}>
                            <Category
                                categoryId={task.category}
                                onCategorySelected={(categoryId) => (
                                    setCategory(dispatchCategories, dispatchTasks, task, categoryId)
                                )}
                            />
                        </div>

                        <div className={styles.Right}>
                            <DeleteButton
                                taskId={task.id}
                                categoryId={task.category}
                                index={index}
                                onDelete={() => (deleteTask(dispatchTasks, dispatchLists, task.list, task.id))}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    )
}

export default Task
