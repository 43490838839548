import React from 'react'

import styles from './styles.module.scss'
import { useSubstate } from 'react-substate'
import substates from '../../../modules/substates'
import Modal from '../../_shared/Modal/Modal'

const CompletedTasks = () => {
    const [tasks] = useSubstate(substates.tasks)

    return (
        <Modal title='Completed Tasks'>
            {
                tasks.completed.map((taskId) => (
                    <div
                        key={taskId}
                        className={styles.CompletedTask}
                    >
                        {tasks.db[taskId].text}
                    </div>
                ))
            }
        </Modal>
    )
}

export default CompletedTasks
