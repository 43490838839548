import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSubstate } from 'react-substate'

import { ReactComponent as ListCheckedIcon } from '../../../images/list--checked.svg'
import { ReactComponent as TrashCanIcon } from '../../../images/trash-can.svg'
import { ReactComponent as UserIcon } from '../../../images/user.svg'
import { ReactComponent as UserCheckIcon } from '../../../images/user--check.svg'
import styles from './styles.module.scss'
import substates from '../../../modules/substates'

const ROUTE_COMPLETED_TASKS = '/completed-tasks'
const ROUTE_DELETED_TASKS = '/deleted-tasks'
const ROUTE_USER_SETTINGS = '/settings/user'

const TopBar = () => {
    const [ui] = useSubstate(substates.ui)
    const [tasks] = useSubstate(substates.tasks)
    const history = useHistory()

    const navigateTo = (route) => {
        if (history.location.pathname !== route) {
            history.push(route)
        }
    }

    return (
        <div className={styles.TopBar}>
            <div className={styles.SiteTitle}>
                To-Do List
            </div>
            <div className={styles.Toolbar}>
                    <button
                        className={styles.Button}
                        onClick={() => (navigateTo(ROUTE_COMPLETED_TASKS))}
                    >
                        <ListCheckedIcon className={styles.Icon} />
                        <div className={styles.CompletedTasksNumber}>
                            {tasks.completed.length}
                        </div>
                    </button>

                    <button
                        className={styles.Button}
                        onClick={() => (navigateTo(ROUTE_DELETED_TASKS))}
                    >
                        <TrashCanIcon className={styles.Icon} />
                    </button>

                    <button
                        className={styles.Button}
                        onClick={() => (navigateTo(ROUTE_USER_SETTINGS))}
                    >
                        {
                            ui.user
                            ? <UserCheckIcon className={styles.Icon} />
                            : <UserIcon className={styles.Icon} />
                        }
                    </button>
            </div>
        </div>
    )
}

export default TopBar
