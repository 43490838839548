import React, { useState } from 'react'

import TextInput from '../../../../_shared/TextInput/TextInput'
import styles from './styles.module.scss'

const Content = ({text, onEditFinish}) => {
    const [isEditing, setEditing] = useState(false)

    const handleTextInputFinished = (value) => {
        if (onEditFinish) {
            onEditFinish(value)
        }

        // hide the text input
        setEditing(false)
    }

    if (isEditing) {
        return (
            <div className={styles.ContentContainer}>
                <TextInput
                    className={styles.TextInput}
                    initialValue={text}
                    placeholder=''
                    focusOnShow
                    onBlur={handleTextInputFinished}
                    onEnter={handleTextInputFinished}
                />
            </div>
        )
    } else {
        return (
            <div className={styles.ContentContainer}>
                <div
                    className={styles.Content}
                    onClick={() => (setEditing(true))}
                >
                    {text}
                </div>
            </div>
        )

    }
}

export default Content
