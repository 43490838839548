import  React, { useEffect } from 'react'
import { useSubstate } from 'react-substate'

import substates from '../../../../../modules/substates'
import styles from './styles.module.scss'
import CategoryButton from './CategoryButton'

const Selector = ({isSelectorVisible, onCategorySelected, onFinished}) => {
    const [categories] = useSubstate(substates.categories)

    useEffect(() => {
        const handleSelectorFinished = () => {
            if (onFinished) {
                onFinished()
            }
        }

        // If we are currently selecting a category, add a global click handler
        if (isSelectorVisible) {
            window.addEventListener('click', handleSelectorFinished)
        }

        return () => {
            // clean up the global click handler
            window.removeEventListener('click', handleSelectorFinished)
        }
    }, [isSelectorVisible, onFinished])

    const handleCategorySelected = (categoryId) => {
        if (onCategorySelected) {
            onCategorySelected(categoryId)
        }
    }

    if (!isSelectorVisible) {
        return null
    }

    return (
        <div className={styles.SelectorContainer}>
            <div className={styles.Selector}>
                {
                    Object.keys(categories).map((categoryId) => (
                        <CategoryButton
                            key={categoryId}
                            categoryName={categories[categoryId].name}
                            categoryColor={categories[categoryId].color}
                            onClick={() => (handleCategorySelected(categoryId))}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default Selector
