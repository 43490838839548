import styles from './styles.module.scss'

import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import TaskList from './TaskList/TaskList'
import { useSubstate } from 'react-substate'
import substates from '../../../modules/substates'
import TopBar from '../../_shared/TopBar/TopBar'
import { moveTask } from '../../../modules/TaskOperations'

const Home = () => {
    const [tasks, dispatchTasks] = useSubstate(substates.tasks)
    const [lists, dispatchLists] = useSubstate(substates.lists)

    /**
     * Utility function to update the task ordering within lists and to dispatch any relevant
     * change events.
     * @param {*} result Result object received from react-beautiful-dnd
     */
    const handleDragEnd = (result) => {
        // Check if it was dropped outside of the list
        if (!result.destination) {
            return
        }

        const taskId = result.draggableId
        const sourceListId = tasks.db[taskId].list
        const destinationIndex = result.destination.index
        const destinationListId = result.destination.droppableId

        moveTask(
            dispatchLists,
            dispatchTasks,
            taskId,
            sourceListId,
            destinationListId,
            destinationIndex
        )
    }

    /**
     * Utility function to take a list of task ids and turn them into an array of task objects.
     *
     * @param {string[]} taskIds THe list of task ids.
     * @returns {object[]} Array of task objects.
     */
    const getTasksByIds = (taskIds) => {
        if (!taskIds) {
            return null
        } else {
            return(
                taskIds.map((id) => (
                    tasks.db[id]
                ))
            )
        }
    }

    return (
        <>
            <TopBar />
            <DragDropContext onDragEnd={handleDragEnd}>
                <div className={styles.Home}>
                    {lists.order.map((listId) => (
                        <TaskList
                            listId={listId}
                            listName={lists.db[listId].name}
                            tasks={getTasksByIds(lists.db[listId].tasks)}
                            key={listId}
                        />
                    ))}
                </div>
            </DragDropContext>
        </>
    )
}

export default Home
