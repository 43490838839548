import React, { useState } from 'react'

import styles from './styles.module.scss'

const CategoryButton = ({categoryColor, categoryName, onClick, tooltip = false}) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false)

    const handleButtonClick = () => {
        if (onClick) {
            onClick()
        }
    }

    const Tooltip = () => {
        if (!isTooltipVisible || !tooltip) {
            return null
        } else {
            return (
                <div className={styles.Tooltip}>
                    {categoryName}
                </div>
            )
        }
    }

    return (
        <div className={styles.CategoryButtonContainer}>
            <button
                aria-label={`${categoryName} category button`}
                className={styles.CategoryButton}
                style={{
                    backgroundColor: categoryColor
                }}
                onClick={handleButtonClick}
                onMouseEnter={() => (setTooltipVisible(true))}
                onMouseLeave={() => (setTooltipVisible(false))}
            />
            <Tooltip />
        </div>
    )
}

export default CategoryButton
