const isDevelopment = process.env.NODE_ENV === 'development'

const TEST_ID_AUTH_TOKEN = 'abc123'

const siteConfig = {
    apiKey: 'AIzaSyDCc81U-JCZZ1RwAqhmsQ8_SV_gljW48Fg',
    authDomain: window.location.host,
    databaseURL: isDevelopment
        ? 'http://localhost:9000?ns=harvtronix-website'
        : 'https://harvtronix-website.firebaseio.com',
    databaseName: 'harvtronix-website',
    projectId: 'harvtronix-website',
    storageBucket: 'harvtronix-website.appspot.com',
    messagingSenderId: '44630010035',
    appId: '1:44630010035:web:bd6b5cd9d1c30048f9a8c6',
    measurementId: 'G-PR70QNK4RP',
    auth: {
        uid: 'u111111',
        email: 'u111111@example.com'
    }
}

const importFirebase = async () => {
    if (isDevelopment) {
        return await import('@firebase/testing')
    } else {
        const firebase = await import('firebase/app')

        await import('firebase/auth')
        await import('firebase/database')

        return firebase
    }
}

const createApp = async (onAuthStateChanged) => {
    const firebase = await importFirebase()

    if (isDevelopment) {
        const app = firebase.initializeTestApp(siteConfig)

        // set up custom hooks for auth mocking
        app.__internal__ = {
            onAuthStateChanged
        }

        return app
    } else {
        const app = firebase.initializeApp(siteConfig)

        // Set up the auth observer
        app.auth().onAuthStateChanged(onAuthStateChanged)

        return app;
    }
}

const doGoogleLogin = async (app, onSuccess, onFailure) => {
    if (isDevelopment) {
        // https://firebase.google.com/docs/reference/js/firebase.auth#usercredential
        onSuccess({
            credential: {
                accessToken: TEST_ID_AUTH_TOKEN
            },
            user: {
                uid: siteConfig.auth.uid
            }
        })

        // Fire a simulated onAuthStateChanged event, passing it a mocked user
        app.__internal__.onAuthStateChanged({
            uid: siteConfig.auth.uid,
            getIdToken: () => (TEST_ID_AUTH_TOKEN)
        })
    } else {
        const firebase = await importFirebase()
        const provider = new firebase.auth.GoogleAuthProvider()

        // Show the actual login popup. Succeeding here will update the internally managed uid and
        // auth of the app, which allows subsequent database calls to work.
        app.auth().signInWithPopup(provider)
            .then(onSuccess)
            .catch(onFailure)
    }
}

const getData = async (app, user) => {
    if (!app || !user) {
        return null
    }

    const response = await app.database().ref(`/users/${user.uid}/data`).once('value')

    return JSON.parse(response.toJSON())
}

const setData = async (app, user, data) => {
    if (!app || !user || !data) {
        return
    }

    await app.database().ref(`/users/${user.uid}/data`).set(JSON.stringify(data))
}

export {
    createApp,
    doGoogleLogin,
    getData,
    setData
}
