import React, { useRef } from 'react'

import styles from './styles.module.scss'
import { ReactComponent as CloseIcon } from '../../../images/close.svg'
import { useHistory } from 'react-router-dom'

const Modal = ({title, children, prev}) => {
    const history = useHistory()
    const modalRef = useRef()

    const handleModalClick = (e) => {
        if (e.target === modalRef.current) {
            closeModal()
        }
    }

    const closeModal = () => {
        if (prev) {
            history.replace(prev)
        } else {
            history.replace('/')
        }
    }

    return (
        <div
            className={styles.Modal}
            onClick={handleModalClick}
            ref={modalRef}
        >
            <div className={styles.ContentContainer}>
                <div className={styles.Header}>
                    <h1>
                        {title}
                    </h1>
                    <button
                        onClick={closeModal}
                        className={styles.CloseButton}
                    >
                        <CloseIcon className={styles.CloseIcon} />
                    </button>
                </div>

                <div className={styles.Content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal
