import React from 'react'

import Modal from '../../_shared/Modal/Modal'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import UserSettings from './user/UserSettings'

const Routes = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={path + '/user'} component={UserSettings} />
            <Redirect to={path + '/user'} />
        </Switch>
    )
}

const Settings = () => {
    return (
        <Modal title='Settings'>
            <Routes />
        </Modal>
    )
}

export default Settings
