import actions from '../modules/actions'

const createTask = (dispatchTasks, dispatchLists, dispatchCategories, listId, inputValue) => {
    // Do nothing if the text is empty
    if (inputValue === '') {
        return
    }

    const taskId = `task-${Date.now()}-${Number.parseInt(Math.random()*10000)}`

    // Add to the db
    dispatchTasks(actions.tasks.addTask, {
        taskId,
        listId,
        text: inputValue
    })

    // Add to the list
    dispatchLists(actions.lists.addTask, {taskId, listId})

    // Add to the correct category
    dispatchCategories(actions.categories.addTask, {taskId})
}

const updateText = (dispatchTasks, value, taskId) => {
    // Do nothing if the value is empty
    if (value === '') {
        return
    }

    // Commit the current text
    dispatchTasks(actions.tasks.updateTask, {
        taskId,
        fields: {
            text: value
        }
    })
}

const moveTask = (
    dispatchLists,
    dispatchTasks,
    taskId,
    sourceListId,
    destinationListId,
    destinationIndex
) => {
    // Update the lists
    dispatchLists(actions.lists.removeTask, {
        listId: sourceListId,
        taskId
    })
    dispatchLists(actions.lists.addTask, {
        taskId,
        listId: destinationListId,
        index: destinationIndex
    })

    // Update the task itself
    dispatchTasks(actions.tasks.updateTask, {
        taskId,
        fields: {
            list: destinationListId
        }
    })
}

const deleteTask = (dispatchTasks, dispatchLists, listId, taskId) => {
    // Update deleted tasks list
    dispatchTasks(actions.tasks.markDeleted, {taskId})

    // Remove from containing list
    dispatchLists(actions.lists.removeTask, {listId, taskId})
}

const completeTask = (dispatchTasks, dispatchLists, listId, taskId) => {
    // Update completed tasks list
    dispatchTasks(actions.tasks.markCompleted, {taskId})

    // Remove from containing list
    dispatchLists(actions.lists.removeTask, {listId, taskId})
}

const setCategory = (dispatchCategories, dispatchTasks, task, categoryId) => {
    // Update the categories substate
    dispatchCategories(actions.categories.removeTask, {
        taskId: task.id,
        categoryId: task.category
    })
    dispatchCategories(actions.categories.addTask, {
        taskId: task.id,
        categoryId
    })

    // Update the task itself substate
    dispatchTasks(actions.tasks.updateTask, {
        taskId: task.id,
        fields: {
            category: categoryId
        }
    })
}

export {
    createTask,
    updateText,
    deleteTask,
    completeTask,
    moveTask,
    setCategory
}
