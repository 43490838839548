import { createAction } from 'react-substate'
import constants from './constants'

const actions = {
    ui: {
        setApp: createAction((draft, payload) => {
            draft.app = payload
        }),

        setInitialized: createAction((draft, payload) => {
            draft.isInitialized = payload
        }),

        setUser: createAction((draft, payload) => {
            draft.user = payload
        }),
    },

    tasks: {
        initialize: createAction((_, payload) => {
            return payload
        }),

        addTask: createAction((draft, payload) => {
            const {taskId, text, listId} = payload

            const newTask = makeTask({
                taskId,
                listId,
                text
            })

            // Add it to the db
            draft.db[newTask.id] = newTask

            // Add it to the active tasks
            draft.active.push(newTask.id)
        }),

        updateTask: createAction((draft, payload) => {
            const {taskId, fields} = payload

            draft.db[taskId] = {
                ...draft.db[taskId],
                ...fields
            }
        }),

        markCompleted: createAction((draft, payload) => {
            const {taskId} = payload
            const index = draft.active.indexOf(taskId)

            // Remove from active list
            draft.active.splice(index, 1)

            // Add to completed list
            draft.completed.push(taskId)
        }),

        markDeleted: createAction((draft, payload) => {
            const {taskId} = payload
            const index = draft.active.indexOf(taskId)

            // Remove from active list
            draft.active.splice(index, 1)

            // Add to deleted list
            draft.deleted.unshift(taskId)

            // Cap the number of entires
            while (draft.deleted.length > constants.MAX_DELETED_TASKS) {
                // Remove from deleted list
                const deleted = draft.deleted.pop()

                // Remove from db
                delete draft.db[deleted]
            }
        })
    },

    lists: {
        initialize: createAction((_, payload) => {
            // ensure each list has a tasks array
            Object.keys(payload.db).forEach((listKey) => {
                const list = payload.db[listKey]
                if (!('tasks' in list)) {
                    list.tasks = []
                }
            })

            return payload
        }),

        addTask: createAction((draft, payload) => {
            const {taskId, listId, index} = payload

            if (index === undefined) {
                // Place the element at the end of the list
                draft.db[listId].tasks.push(taskId)
            } else {
                // Place the element in the list
                draft.db[listId].tasks.splice(index, 0, taskId)
            }
        }),

        removeTask: createAction((draft, payload) => {
            const {listId, taskId} = payload

            // Find the index in the list
            const index = draft.db[listId].tasks.indexOf(taskId)

            // Remove the element from the list
            draft.db[listId].tasks.splice(index, 1)
        })
    },

    categories: {
        initialize: createAction((_, payload) => {
            return payload
        }),

        addTask: createAction((draft, payload) => {
            const {taskId, categoryId=constants.DEFAULT_CATEGORY} = payload

            // Add to category
            draft[categoryId].tasks.push(taskId)
        }),

        removeTask: createAction((draft, payload) => {
            const {taskId, categoryId} = payload

            // Remove from category
            const indexInCategory = draft[categoryId].tasks.indexOf(taskId)
            if (indexInCategory >= 0) {
                draft[categoryId].tasks.splice(indexInCategory, 1)
            }
        })
    }
}

function makeTask ({
    taskId,
    listId,
    text,
    category = constants.DEFAULT_CATEGORY
}) {
    // Generate a new ID for the task
    const timeCreated = Date.now()

    return {
        id: taskId,
        list: listId,
        text,
        category,
        timeCreated
    }
}

function hydrate (dispatchTasks, dispatchLists, dispatchCategories, persistedData) {
    dispatchTasks(actions.tasks.initialize, persistedData.tasks)
    dispatchLists(actions.lists.initialize, persistedData.lists)
    dispatchCategories(actions.categories.initialize, persistedData.categories)
}

export default actions
export { hydrate }
