const VERSION = 3

const persist = async (jsonObj) => {
    // Add in the current data model version
    jsonObj['version'] = VERSION

    if ('localStorage' in window) {
        window.localStorage.setItem('persistedData', JSON.stringify(jsonObj))
    } else {
        throw new Error('This app does not work without local storage!')
    }
}

const retrieve = () => {
    if (!('localStorage' in window)) {
        throw new Error('This app does not work without local storage!')
    }

    const data = window.localStorage.getItem('persistedData')
    if (data) {
        const parsedData = JSON.parse(data)

        switch(parsedData.version) {
        case 1: // to 2
            parsedData.data.lists['list-3'] = {
                id: 'list-3',
                name: 'Maybe someday',
                tasks: []
            }
            parsedData.data.listOrder = [
                'list-3',
                'list-1',
                'list-2',
            ]
            break
        case 2: // to 3
            parsedData.tasks = {
                db: parsedData.data.tasks,
                active: Object.keys(parsedData.data.tasks),
                completed: parsedData.completedTasks.map((task) => (task.id)),
                deleted: parsedData.deletedTasks.map((task) => (task.id)),
            }

            parsedData.completedTasks.forEach((task) => {
                parsedData.tasks.db[task.id] = task
            })
            parsedData.deletedTasks.forEach((task) => {
                parsedData.tasks.db[task.id] = task
            })

            Object.values(parsedData.tasks.db).forEach((task) => {
                if (!('timeCreated' in task)) {
                    task.timeCreated = Date.now()
                }
            })

            parsedData.lists = {
                db: parsedData.data.lists,
                order: parsedData.data.listOrder
            }

            delete parsedData.data
            delete parsedData.completedTasks
            delete parsedData.deletedTasks
            break
        default:
            break
        }

        return parsedData
    }

    return null
}

export {
    persist,
    retrieve
}
