import React, { useRef } from 'react'
import styles from './styles.module.scss'

import Task from '../Task/Task'
import { Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-substate'
import TextInput from '../../../_shared/TextInput/TextInput'
import { createTask } from '../../../../modules/TaskOperations'
import substates from '../../../../modules/substates'

const TaskList = ({listId, listName, tasks}) => {
    const dispatchTasks = useDispatch(substates.tasks)
    const dispatchLists = useDispatch(substates.lists)
    const dispatchCategories = useDispatch(substates.categories)
    const listChildRef = useRef()

    const handleInputSubmit = (inputValue) => {
        createTask(dispatchTasks, dispatchLists, dispatchCategories, listId, inputValue)
        doScroll()
    }

    const doScroll = () => {
        if (listChildRef && listChildRef.current) {
            setTimeout(() => {
                listChildRef.current.parentNode.scroll({
                    top: listChildRef.current.parentNode.scrollHeight,
                    behavior: 'smooth'
                })
            }, 0)
        }
    }

    return (
        <div className={styles.TaskListPadding}>
            <div className={styles.TaskListContainer}>
                <h1>
                    {listName}
                </h1>
                <Droppable droppableId={listId}>
                    {(provided) => (
                        <div
                            className={styles.TaskList}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {tasks && tasks.map((task, index) => {
                                // loop through and render Task components for each entry in this
                                // list
                                return (
                                    <Task
                                        key={task.id}
                                        task={task}
                                        index={index}
                                    />
                                )
                            })}

                            {provided.placeholder}

                            <div className={styles.InputContainer} ref={listChildRef}>
                                <TextInput
                                    className={styles.TextInput}
                                    onEnter={handleInputSubmit}
                                />
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        </div>
    )
}

export default TaskList
