import React from 'react'

import styles from './styles.module.scss'
import { ReactComponent as CheckmarkIcon } from '../../../../../images/checkmark.svg'

const CompleteButton = ({onComplete}) => {
    const handleCompleteClick = () => {
        if (onComplete) {
            onComplete()
        }
    }

    return (
        <button className={styles.CompleteButton} onClick={handleCompleteClick}>
            <CheckmarkIcon className={styles.Icon} />
        </button>
    )
}

export default CompleteButton
